import Client from '../services/da-sdk';
import { getToken } from '../utils/AuthProvider';

const denseairClient = new Client({
  baseUrl: import.meta.env.VITE_APP_DENSEAIR_API_URL,
  version: 'v1',
  getToken: () => getToken() ?? '',
});

export default denseairClient;
