import { PATHS } from '../../constants';
import Authorize from '../../pages/Authorize/Authorize';
import Homepage from '../../pages/Homepage/Homepage';
import Login from '../../pages/Login/Login';
import { loggedInGuard } from './App.helpers';

export const routes = {
  [PATHS.HOME_PATH]: () =>
    loggedInGuard(<Homepage />, `/login?redirectUrl=${window.location.toString()}`),
  [PATHS.LOGIN_PATH]: () => <Login />,
  [PATHS.AUTHORIZE_PATH]: () => <Authorize />,
};
