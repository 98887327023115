import { SmallCloseIcon } from '@chakra-ui/icons';
import { Box, Tag } from '@chakra-ui/react';
import { FC } from 'react';

type TagOptions = {
  listName: string;
  label: string;
  value: string;
  delFunc: (id: string, item: string) => void;
  onClickFunc?: () => void;
};

const TagComponent: FC<TagOptions> = ({ listName, label, value, delFunc, onClickFunc }) => {
  return (
    <Box display={'flex'} mr={'10px'}>
      <Tag
        id={`tag-${label}`}
        background={'primary'}
        borderLeftRadius={'15px'}
        borderRightRadius={'0px'}
        color={'white'}
        p={'5px 10px'}
        _hover={{
          ...(onClickFunc && {
            cursor: 'pointer',
            textDecoration: 'underline',
          }),
          background: 'primaryDarkFifteenPercent',
          transition: 'all 0.3s',
        }}
        onClick={onClickFunc}
      >
        {label}
      </Tag>
      <Box
        borderLeft={'1px solid #99AEBB'}
        borderRightRadius={'15px'}
        background={'primary'}
        display={'flex'}
        justifyItems={'center'}
        alignItems={'center'}
        pr={'5px'}
        _hover={{ cursor: 'pointer', background: 'errorRed' }}
      >
        <SmallCloseIcon
          fontSize={'1.5em'}
          color={'white'}
          ml={'1px'}
          onClick={() => {
            delFunc(listName, value);
          }}
        />
      </Box>
    </Box>
  );
};

export default TagComponent;
