import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { GrKey, GrLogout } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/useAppSelector';
import useLogOut from '../../hooks/useLogOut';
import { PersonalizationModalState, ThresholdTab } from '../../store/modalTabSlice';
import ChangePassword from '../ChangePassword';
import NodeCollections from '../PersonalizationModalOptions/NodeCollections';
import Thresholds from '../PersonalizationModalOptions/Thresholds';

type ModalOptions = {
  currentState: boolean;
};

const Personalization: FC<ModalOptions> = ({ currentState }) => {
  const toggleThreshold = useAppSelector((state) => state.modalTabSettings.tabState);
  const dispatch = useDispatch();

  const logout = useLogOut();

  return (
    <Modal
      isOpen={currentState}
      size={'6xl'}
      onClose={() => dispatch(PersonalizationModalState(false))}
    >
      <ModalOverlay />
      <ModalContent p={'34px 20px 35px 20px'} background={'lightGrey'}>
        <ModalCloseButton
          id={'user-profile-modal-close-button'}
          top={0}
          right={0}
          padding={0}
          color={'black'}
        />
        <ModalHeader background={'white'} borderRadius={7} mb={5}>
          <Heading size={'md'}>Personalization</Heading>
        </ModalHeader>
        <ModalBody
          display={'grid'}
          gridTemplateColumns={'1fr 4fr'}
          gap={5}
          background={'white'}
          borderRadius={7}
          p={5}
        >
          <VStack alignItems={'left'} height={'600px'} justifyContent={'space-between'}>
            <Box>
              <Button
                background={'primary'}
                mb={'10px'}
                width={'100%'}
                onClick={() => {
                  dispatch(ThresholdTab('Threshold'));
                }}
              >
                Thresholds
              </Button>
              <Button
                background={'primary'}
                width={'100%'}
                onClick={() => {
                  dispatch(ThresholdTab('NodeCollection'));
                }}
              >
                Node Collections
              </Button>
            </Box>
            <Box>
              <Button variant="link">
                <HStack>
                  <GrKey color="white" />
                  <ChangePassword />
                </HStack>
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  logout({ triggerToast: false });
                }}
              >
                <HStack>
                  <GrLogout />
                  <Box>Log Out</Box>
                </HStack>
              </Button>
            </Box>
          </VStack>
          <Box>
            {toggleThreshold === 'Threshold' ? (
              <Thresholds />
            ) : toggleThreshold === 'NodeCollection' ? (
              <NodeCollections />
            ) : null}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Personalization;
