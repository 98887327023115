import {
  Box,
  Button,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaKey } from 'react-icons/fa';
import Auth from '../../helpers/Auth';
import dwAxiosClient from '../../services/dwAxiosClient';

interface FormData {
  currentPassword: string;
  newPassword: string;
  newPasswordConf: string;
}

const ChangePassword: FC = () => {
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    newPasswordConf: '',
  };

  const userId = Auth.getAuthProperty('sub');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [passMatch, setPassMatch] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [successMessage, setSuccessMessage] = useState(false);
  const [showPassword, { toggle: toggle1 }] = useBoolean(false);
  const [showPassword2, { toggle: toggle2 }] = useBoolean(false);
  const [showPassword3, { toggle: toggle3 }] = useBoolean(false);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      currentPassword: initialValues.currentPassword,
      newPassword: initialValues.newPassword,
      newPasswordConf: initialValues.newPasswordConf,
    },
  });

  const newPassword = watch('newPassword');
  const newPasswordConf = watch('newPasswordConf');

  useEffect(() => {
    const nP = getValues('newPassword');
    const nPC = getValues('newPasswordConf');

    if (nP !== nPC) {
      setPassMatch(false);
      setSuccessMessage(false);
      setErrorMessage('New passwords do not match');
    } else {
      setErrorMessage('');
      setPassMatch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, newPasswordConf]);

  const onSubmit = async (data: FormData | null) => {
    const userData = {
      UserId: userId.value,
      CurrentPass: data?.currentPassword,
      NewPassword: data?.newPassword,
    };

    await dwAxiosClient
      .post(`${import.meta.env.VITE_APP_IDS_URL}/api/auth/changepassword`, userData)
      .then((success) => {
        if (success.status === 200) {
          setSuccessMessage(true);
          setErrorMessage(false);
          reset(initialValues);
        }
      })
      .catch((error: AxiosError<any>) => {
        if (typeof error.response?.data === 'string') {
          setSuccessMessage(false);
          setErrorMessage(error.response.data);
        } else {
          const errorEntries = Object.entries<string[]>(error.response?.data.errors);
          const errors = errorEntries.reduce((previousValue, [errorField, errorMessage]) => {
            if (previousValue) {
              previousValue = previousValue.concat(`, ${errorField}: ${errorMessage.join(',')}`);
            } else {
              previousValue = `${errorField}: ${errorMessage.join(',')}`;
            }
            return previousValue;
          }, '');
          setErrorMessage(errors);
          setSuccessMessage(false);
        }
      });
  };

  return (
    <>
      <Text id={'change-password-trigger-button'} onClick={onOpen}>
        Change Password
      </Text>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          width={'600px'}
          padding={'20px'}
          background={'var(--chakra-colors-blackAlpha-600)'}
        >
          <ModalHeader color={'white'}>Change your Password</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.currentPassword}>
              <InputGroup mb={'15px'}>
                <InputLeftElement
                  color={'white'}
                  pointerEvents="none"
                  children={<Icon as={FaKey} />}
                />
                <Input
                  {...register('currentPassword', {
                    required: 'Required',
                  })}
                  color={'white'}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Current Password"
                  id="currentPassword"
                  defaultValue={initialValues.currentPassword}
                  formNoValidate
                />
                <InputRightAddon fontSize={'sm'} cursor="pointer" onClick={toggle1}>
                  {showPassword ? 'HIDE' : 'SHOW'}
                </InputRightAddon>
              </InputGroup>
            </FormControl>

            <FormControl isInvalid={!!errors.newPassword}>
              <InputGroup mb={'15px'}>
                <InputLeftElement
                  color={'white'}
                  pointerEvents="none"
                  children={<Icon as={FaKey} />}
                />
                <Input
                  {...register('newPassword', {
                    required: 'required',
                    minLength: {
                      value: 8,
                      message: 'New password should be more than 8 characters',
                    },
                    maxLength: {
                      value: 50,
                      message: 'New password should be less than 50 characters',
                    },
                  })}
                  color={'white'}
                  type={showPassword2 ? 'text' : 'password'}
                  placeholder="New Password"
                  name="newPassword"
                  formNoValidate
                />
                <InputRightAddon fontSize={'sm'} cursor="pointer" onClick={toggle2}>
                  {showPassword2 ? 'HIDE' : 'SHOW'}
                </InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl isInvalid={!!errors.newPasswordConf}>
              <InputGroup mb={'15px'}>
                <InputLeftElement
                  color={'white'}
                  pointerEvents="none"
                  children={<Icon as={FaKey} />}
                />
                <Input
                  {...register('newPasswordConf', {
                    required: 'New password confirmation required',
                  })}
                  color={'white'}
                  type={showPassword3 ? 'text' : 'password'}
                  placeholder="Confirm new password"
                  name="newPasswordConf"
                  formNoValidate
                />
                <InputRightAddon fontSize={'sm'} cursor="pointer" onClick={toggle3}>
                  {showPassword3 ? 'HIDE' : 'SHOW'}
                </InputRightAddon>
              </InputGroup>
            </FormControl>

            {errors.newPassword?.message ||
            errors.currentPassword?.message ||
            !passMatch ||
            errorMessage ? (
              <Box
                color={'white'}
                border={'1px solid red'}
                borderRadius={'3px'}
                mb={'20px'}
                p={'10px'}
                background={'red'}
              >
                {errors.newPassword?.message ||
                  errors.newPasswordConf?.message ||
                  errors.currentPassword?.message ||
                  errorMessage}
              </Box>
            ) : null}

            {successMessage ? (
              <Box
                color={'white'}
                border={'1px solid #4ec849'}
                borderRadius={'3px'}
                mb={'20px'}
                p={'10px'}
                background={'#4ec849'}
              >
                Your password is updated
              </Box>
            ) : null}
            {isSubmitting}
            <Button isLoading={isSubmitting} type="submit">
              Change Password
            </Button>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangePassword;
