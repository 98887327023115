import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Flex,
  Grid,
  Heading,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from '@chakra-ui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from 'react-icons/ai';
import Select from 'react-select';
import { z } from 'zod';
import { colorOptions } from '../../config/colorOptions';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

import { useGetMeasuresListClient } from '../../services/measureClient';
import { useGetThresholdLists, useUpdateUserThresholdList } from '../../services/userProfileClient';
import { ThresholdEditFormState } from '../../store/modalTabSlice';
import { KPIList, KPIValues, ThresholdListValue } from './ThresholdsForm';

const EditThresholdsForm: FC = () => {
  const [listName, setListName] = useState<string>('');
  const [KPIId, setKpiId] = useState<string | null>(null);
  const { register, handleSubmit } = useForm<KPIValues>({
    defaultValues: {
      name: listName,
    },
  });

  const updateThresholdList = useUpdateUserThresholdList();

  const toast = useToast();
  const [thresholdValue, setThresholdValue] = useState<string>('');
  const isValueANumber =
    thresholdValue === '' ? { success: false } : z.coerce.number().safeParse(thresholdValue);
  let titleOptions: { label: string; value: string }[] = [];
  const [addedKPIList, setAddedKPIList] = useState<KPIList>([]);
  const [showValueInput, setShowValueInput] = useState<boolean>();
  const [showValueIndex, setShowValueIndex] = useState<number>();
  const [kpiValue] = useState<string>('');
  const [thresholdColor, setThresholdColor] = useState<{
    label: string | null;
    value: string | null;
  }>({ label: '', value: '' });

  const addedData: KPIList = [...addedKPIList];

  const id = useAppSelector((state) => state.modalTabSettings.thresholdsEditForm.thresholdEditId);

  const dispatch = useAppDispatch();

  const { data: thresholdListData, refetch: refetchList } = useGetThresholdLists();

  const { data, status } = useGetMeasuresListClient();

  const KPIName = KPIId && data ? data.data.data[KPIId].title : null;

  if (status === 'success') {
    titleOptions = Object.entries(data.data.data).map((item) => {
      const option = {
        label: item[1].title,
        value: item[0],
      };
      return option;
    });
  }

  const profileData = thresholdListData?.find((profile) => {
    return profile.userProfileId === id;
  });

  const profilePayload = profileData?.payload;
  const listData: ThresholdListValue = useMemo(
    () => {
      if (profilePayload) {
        return JSON.parse(profilePayload);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profileData?.userProfileId]
  );

  useEffect(() => {
    if (listData) {
      setListName(listData.listName);
      setAddedKPIList(listData.values);
    }
  }, [listData]);

  const onSubmit = (data: KPIValues) => {
    const addedKpi = {
      name: KPIName,
      id: KPIId,
      value: data.value,
      color: thresholdColor.value,
    };

    addedData.push(addedKpi);
    setAddedKPIList(addedData);
    setThresholdValue('');
    setKpiId('');
  };

  const onDeleteKPI = (index: number | null) => {
    setAddedKPIList(
      addedKPIList.filter((ele, i) => {
        return i !== index;
      })
    );
  };

  const changeValue = (index: number) => {
    setShowValueIndex(index);
    setShowValueInput(!showValueInput);
  };

  const handleValueChange = (newValue: number, index: number | null) => {
    const newList = addedKPIList.map((item, i) => {
      if (i === index) {
        item.value = newValue;
      }
      return item;
    });
    setAddedKPIList(newList);
  };

  const handleColorChange = (newColor: string, index: number | null) => {
    const newList = addedKPIList.map((item, i) => {
      if (i === index) {
        item.color = newColor;
      }
      return item;
    });
    setAddedKPIList(newList);
  };

  const onThresholdUpdate = () => {
    const otherCollections = thresholdListData?.filter((item) => {
      if (item.sectionName === 'Threshold') {
        return item.userProfileId !== id;
      }
      return null;
    });

    const listNameExists = otherCollections?.find((item) => {
      const payload = JSON.parse(item.payload);
      const newListName: string = payload.listName;
      if (newListName === listName) {
        return listName;
      }
      return null;
    });

    const data: ThresholdListValue = {
      listName: listName,
      values: addedKPIList,
    };

    if (id && listNameExists === undefined) {
      updateThresholdList.mutate(
        { id, data },
        {
          onSuccess: () => {
            refetchList();
            toast({
              title: 'Your list is updated',
              description: '',
              status: 'success',
              duration: 3000,
              isClosable: true,
              position: 'top',
            });
            dispatch(
              ThresholdEditFormState({
                isThresholdEditFormOpen: false,
                thresholdEditId: null,
              })
            );
          },
        }
      );
    } else if (listNameExists) {
      toast({
        title: 'List Name already exists',
        description: 'Please change your List Name',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return null;
    }
  };

  return (
    <>
      <Grid gridTemplateColumns={'1fr 1fr'} gridGap={10} minH={'450px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={'20px'}>
            <Input
              id={'thresholds-list-name-input-field'}
              placeholder="Threshold List Name"
              value={listName}
              onChange={(e) => {
                const inputValue = e.target.value;
                setListName(inputValue.trim());
              }}
            />
          </Box>
          <Box mb={'20px'}>
            <Select
              id={'thresholds-select-dropdown'}
              options={titleOptions}
              onChange={(newValue) => {
                if (newValue !== null) {
                  if (newValue.value) {
                    setKpiId(newValue.value);
                  }
                } else {
                  setKpiId(null);
                }
              }}
              isClearable
              value={KPIId ? { label: KPIName, value: KPIId } : null}
            />
          </Box>
          <Box mb={'20px'}>
            <Input
              id={'add-threshold-value-input-field'}
              placeholder="Add Threshold Value"
              {...register('value')}
              value={thresholdValue}
              onChange={(e) => setThresholdValue(e.target.value)}
            />
          </Box>
          <Box>
            <Select
              id={'color-options-select-dropdown'}
              options={colorOptions}
              placeholder={'Threshold line color'}
              onChange={(color) => {
                if (color !== null) {
                  setThresholdColor({
                    label: color.label,
                    value: color.value,
                  });
                } else {
                  setThresholdColor({
                    label: null,
                    value: null,
                  });
                }
              }}
              isClearable
              value={
                thresholdColor.label
                  ? { label: thresholdColor.label, value: thresholdColor.value }
                  : null
              }
            />
          </Box>
          <Flex>
            <Button
              id={'add-threshold-value-button'}
              type="submit"
              isDisabled={isValueANumber.success === false}
              background={'primary'}
              mt={5}
            >
              Add KPI
            </Button>
          </Flex>
        </form>

        {listName !== '' || addedKPIList.length > 0 ? (
          <Box>
            <Card p={2} maxH={'250px'}>
              <CardHeader>
                <Heading size={'sm'} textTransform={'uppercase'}>
                  {listName}
                </Heading>
              </CardHeader>
              {addedKPIList.map((value, index) => {
                return (
                  <Grid
                    key={index}
                    gridTemplateColumns={'25px 50px 300px 1fr 1fr 1fr'}
                    background={'primary'}
                    color={'white'}
                    borderRadius={7}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    mb={1.5}
                  >
                    <Box
                      ml={'10px'}
                      mr={'10px'}
                      width={'20px'}
                      height={'20px'}
                      borderRadius={'5px'}
                      background={value.color ? value.color : 'primary'}
                    ></Box>
                    <Menu>
                      <Button
                        as={MenuButton}
                        p={'0px'}
                        m={'0px'}
                        width={'10px'}
                        ml={'10px'}
                        background={'primary'}
                      >
                        <ChevronDownIcon p={'0px'} m={'0px'} />
                      </Button>
                      <MenuList>
                        {colorOptions.map((color, i) => {
                          return (
                            <MenuItem
                              color={'black'}
                              key={i}
                              onClick={() => {
                                handleColorChange(color.value, index);
                              }}
                            >
                              {color.label}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    <Text pl={3}>{value.name}</Text>
                    <Button
                      borderRadius={0}
                      p={'10px'}
                      onClick={() => changeValue(index)}
                      background={'primary'}
                    >
                      {showValueInput && showValueIndex === index ? (
                        <Icon as={AiOutlineCheck} />
                      ) : (
                        <Icon as={AiOutlineEdit} />
                      )}
                    </Button>
                    {showValueInput && showValueIndex === index ? (
                      <Input
                        type={'text'}
                        borderRadius={0}
                        background={'white'}
                        color={'black'}
                        width={'40px'}
                        pl={2}
                        pr={2}
                        onChange={(e) => {
                          handleValueChange(JSON.parse(e.target.value), index);
                        }}
                      />
                    ) : (
                      <Text key={index} p={'0 10px'}>
                        {kpiValue !== '' && showValueIndex === index ? kpiValue : value.value}
                      </Text>
                    )}
                    <Button
                      pl={'10px'}
                      background={'primary'}
                      borderLeft={'1px solid white'}
                      borderLeftRadius={'none'}
                      p={0}
                      onClick={() => onDeleteKPI(index)}
                    >
                      <Icon as={AiOutlineClose} color={'white'} />
                    </Button>
                  </Grid>
                );
              })}
            </Card>
          </Box>
        ) : null}
      </Grid>
      <Flex justifyContent={'end'} gap={'20px'}>
        <Button
          id={'edit-thresholds-cancel-button'}
          variant={'unstyled'}
          onClick={() =>
            dispatch(
              ThresholdEditFormState({
                isThresholdEditFormOpen: false,
                thresholdEditId: null,
              })
            )
          }
        >
          Cancel
        </Button>
        <Button
          id={'edit-thresholds-update-button'}
          onClick={onThresholdUpdate}
          background={'primary'}
        >
          Update
        </Button>
      </Flex>
    </>
  );
};

export default EditThresholdsForm;
