import { extendTheme, Theme, ThemeConfig, withDefaultColorScheme } from '@chakra-ui/react';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/700.css';
import buttonTheme from '../../theme/buttonTheme';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const fonts = {
  body: 'Poppins, sans-serif',
  heading: 'Poppins, sans-serif',
};

const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',

  // h1:'1.875rem', weight:bold
  // h2:'1.5rem',  weight: semibold
  // h3:'1.25rem', weight: medium
  // h4:'1rem', weight: medium
  // h5:'0.875rem', weight: medium
  // h6:'0.75rem', weight: medium
};

const fontWeight = {
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const colors = {
  primary: '#37596A',
  primaryDarkFifteenPercent: '#2E4B5A',
  primaryLightFifteenPercent: '#4E7F97',
  // missing secondary colour, placeholder
  secondary: '#99AEBB',

  // colours for popups, alerts etc
  errorRed: '#700202',
  errorRedBg: '#FECECE',
  warningOrange: '#663000',
  warningOrangeBg: '#FFDFC2',
  warningYellow: '#794500',
  warningYellowBg: '#FFF4E5',
  informationBlue: '#E5F6FD',
  informationBlueBg: '#08516F',
  successGreen: '#255325',
  successGreenBg: '#EDF7ED',

  brand: {
    // Denseair initial pallete colours
    '50': '#EEF4F6',
    '100': '#D0DFE7',
    '200': '#B2CBD7',
    '300': '#94B6C7',
    '400': '#75A1B8',
    '500': '#578DA8',
    '500.50': '#578DA832',
    '600': '#467186',
    '700': '#345565',
    '800': '#233843',
    '900': '#111C22',
    // additional colours
    // blue
    deepBlue: '#37596A',
    darkBlue: '#628395',
    blue: '#9FB9C5',
    lightBlue: '#9CB3BF',
    superLightBlue: '#C6DCE8',
    // grey
    culturedGrey: '#efefef',
    lightGrey: '#D0CFCE',
    darkGrey: '#828280',
    // Using these instead of using them directly for bulk edit
    // other colours
    black: '#000000',
    white: '#FFFFFF',
  },
};

const themeExtension = {
  colors,
  config,
  fonts,
  fontSizes,
  fontWeight,
  components: {
    Button: buttonTheme,
  },
};

export const dwTheme = extendTheme(
  themeExtension,
  withDefaultColorScheme({ colorScheme: 'brand' })
);

export type DWThemeExtension = typeof themeExtension;
export type DWTheme = Theme & DWThemeExtension;
