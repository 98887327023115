import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const unstyledButton = defineStyle({
  background: '#EDF2F7',
  padding: '5px 10px',
});

const unstyledLinkButton = defineStyle({
  display: 'flex',
  fontWeight: 100,
  padding: '5px 10px',
  width: '100%',
  justifyContent: 'start',
  _hover: {
    background: 'lightGrey',
  },
});
const unstyledModalButton = defineStyle({
  background: '#EDF2F7',
  padding: '5px 10px',
});
const underLineButton = defineStyle({
  fontWeight: 100,
  background: 'white',
  _hover: {
    textDecoration: 'underline',
  },
});

const buttonTheme = defineStyleConfig({
  variants: {
    underLine: underLineButton,
    unstyled: unstyledButton,
    unstyledLink: unstyledLinkButton,
    unstyledModalButton: unstyledModalButton,
  },
});

export default buttonTheme;
