import { ChakraProvider } from '@chakra-ui/react';
import '@fontsource/poppins';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useNavigate, useRoutes } from 'raviger';
import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import 'swiper/scss';
import { PATHS } from '../../constants';
import Auth from '../../helpers/Auth';
import useLogOut from '../../hooks/useLogOut';
// import { queryClient } from '../../services/queryClient';
import { store } from '../../store/store';
import MainErrorBoundary from '../MainErrorBoundary';
import { routes } from './App.constants';
import './App.scss';
import { dwTheme } from './App.theme';

function AppInner() {
  const componentFromRoute = useRoutes(routes);

  const logout = useLogOut();
  const navigate = useNavigate();

  useEffect(() => {
    const listener = () => {
      const isVisible = document.visibilityState === 'visible';

      if (isVisible) {
        const tokenStatus = Auth.tokenStatus();
        const currentUrl = new URL(window.location.href);
        const currentUrlParams = currentUrl.searchParams;

        if (tokenStatus === 'expired') {
          logout({
            triggerToast: true,
            redirectUrl: false,
          });
        } else if (tokenStatus === 'no-token') {
          logout({ triggerToast: false, redirectUrl: false });
        } else if (tokenStatus === 'valid') {
          if (currentUrlParams.has('redirectUrl')) {
            navigate(currentUrlParams.get('redirectUrl') ?? '');
          } else {
            navigate(PATHS.HOME_PATH);
          }
        }
      }
    };

    document.addEventListener('visibilitychange', listener);

    return () => document.removeEventListener('visibilitychange', listener);
  }, [logout, navigate]);

  return <div className="App">{componentFromRoute}</div>;
}

// RQ
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      staleTime: 0,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <ChakraProvider theme={dwTheme}>
          <MainErrorBoundary>
            <AppInner />
          </MainErrorBoundary>
        </ChakraProvider>
      </ReduxProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
