import { Box, Center, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'raviger';
import { FC, useEffect } from 'react';
import { PATHS } from '../../constants';
import Auth from '../../helpers/Auth';
import Result from '../../helpers/Result';

const Authorize: FC = () => {
  const navigate = useNavigate();
  const currentUrl = new URL(window.location.href);

  const tokenResult = Auth.getToken();
  const tokenStatus = Auth.tokenStatus();

  useEffect(() => {
    const goCheckGo = async () => {
      //check for token
      if (Result.isSuccess(tokenResult) && tokenStatus === 'valid') {
        // have valid token

        // is there a redirect
        const encodedRedirectUrl = currentUrl.searchParams.get('redirectUrl');
        if (encodedRedirectUrl) {
          // although the redirectUrl was encoded when we came from the other app
          // we don't need to decode again. it seems passing it to new URL() automatically decodes
          const redirectUrl = new URL(encodedRedirectUrl);
          const isAllowedRedirect = Auth.isAllowedRedirect(redirectUrl.host);

          // check it is allowed
          if (isAllowedRedirect) {
            // Check if we're checking app access

            if (Auth.isRestrictedApp(redirectUrl.host)) {
              // is restricted, check roles
              const appConfig = Auth.getAppConfig(redirectUrl.host);

              if (appConfig) {
                if (appConfig.accessToInternal) {
                  // is open to internals
                  if (Auth.isInternalUser()) {
                    // Is open to internal and they are. redirect
                    redirectUrl.searchParams.append('token', tokenResult.value);
                    window.location.replace(redirectUrl.toString());
                  } else {
                    if (Auth.isAllowedSpecificApp(appConfig)) {
                      redirectUrl.searchParams.append('token', tokenResult.value);
                      window.location.replace(redirectUrl.toString());
                    } else {
                      navigate(PATHS.HOME_PATH, {
                        replace: true,
                      });
                    }
                  }
                } else {
                  // not open to internals. check for specific role
                  if (Auth.isAllowedSpecificApp(appConfig)) {
                    redirectUrl.searchParams.append('token', tokenResult.value);
                    window.location.replace(redirectUrl.toString());
                  } else {
                    navigate(PATHS.HOME_PATH, {
                      replace: true,
                    });
                  }
                }
              } else {
                // shouldn't happen but didn't find a config after finding it was restricted...
                // error out to the homepage
                navigate(PATHS.HOME_PATH, {
                  replace: true,
                });
              }
            } else {
              // is not restricted and is valid - redirect
              redirectUrl.searchParams.append('token', tokenResult.value);
              window.location.replace(redirectUrl.toString());
            }
          } else {
            // redirect url is not allowed - go to home
            navigate(PATHS.HOME_PATH, { replace: true });
          }
        } else {
          // no redirect present - go to home
          navigate(PATHS.HOME_PATH, { replace: true });
        }
      } else {
        // no token or expired, redirect by replacing to /login?redirectUrl=
        // login page logic takes over
        const loginUrl = new URL(PATHS.LOGIN_PATH, window.location.origin);
        loginUrl.search = currentUrl.search;
        loginUrl.hash = currentUrl.hash;
        window.location.replace(loginUrl);
      }
    };

    goCheckGo();
  }, [
    currentUrl.hash,
    currentUrl.search,
    currentUrl.searchParams,
    navigate,
    tokenResult,
    tokenStatus,
  ]);

  return (
    <Center>
      <Box p="5">
        <Spinner />
      </Box>
    </Center>
  );
};

export default Authorize;
