import { Redirect } from 'raviger';
import Auth from '../../helpers/Auth';

export const loggedInGuard = (next: JSX.Element, redirectPath: string) => {
  if (Auth.tokenStatus() === 'valid') {
    return next;
  } else {
    return <Redirect to={redirectPath} replace={false} />;
  }
};
