import { Grid, GridItem, Image } from '@chakra-ui/react';
import { FC } from 'react';
import Logo from '../../assets/images/logo.svg';
import Profile from '../profile/Profile';

const Header: FC = () => {
  return (
    <Grid
      className="header"
      id="header"
      templateColumns={'1fr 1fr'}
      p={'0.5% 0.5% 0.25% 1%'}
      background={'primary'}
    >
      <GridItem>
        <Image src={Logo} alt="Denseware Logo" display="inline" padding={'10px'} />
      </GridItem>

      {/* For Navigation use  */}

      <GridItem colStart={3} color={'white'} pt={'7px'}>
        {/* Navigation component 
            Enable when needed
         <Button
          background={'primary'}
          _hover={{ bg: 'primaryLightFifteenPercent' }}
          _active={{ bg: 'primaryLightFifteenPercent' }}
        >
        </Button> */}
      </GridItem>
      <GridItem colEnd={6} className="profile" id="user-info">
        <Profile />
      </GridItem>
    </Grid>
  );
};

export default Header;
