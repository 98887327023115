import { Box, Button, Center, HStack, Text } from '@chakra-ui/react';
import { Component, ReactElement } from 'react';
import { Logger } from '../../helpers/Logger';

type ErrorBoundaryProps = {
  children: ReactElement;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    Logger.error('Caught main error', error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box>
          <Box>
            <Text align="center">Something has gone wrong.</Text>
            <Text align="center">
              You can try refreshing, or if that doesn't work, clear the storage and log in again.
            </Text>
          </Box>
          <Center mt="3">
            <HStack>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
              >
                Reload
              </Button>
              <Button
                onClick={() => {
                  localStorage.clear();
                  window.location.assign(`${window.location.origin}`);
                }}
              >
                Clear and login again
              </Button>
            </HStack>
          </Center>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
