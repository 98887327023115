// https://antman-does-software.com/stop-catching-errors-in-typescript-use-the-either-type-to-make-your-code-predictable
// With some changes!

/**
 * Instead of throwing and catching errors we can make use of the `Either`.
 * Then we can use an `if` for happy and unhappy path. Sometimes referred to Left or Right paths.
 * An `Either` can be either a Failure or Success.
 * There are functions to check if an `Either` is `Failure` or `Success` and assert it through typescript.
 * And functions to create the `Failure` or `Success`.
 *
 * @see [Error Handling with Result](https://khalilstemmler.com/articles/enterprise-typescript-nodejs/handling-errors-result-class/)
 * @module
 */

type Failure = {
  error: string;
  value?: never;
};

type Success<U> = {
  error?: never;
  value: U;
};

export type Either<U> = NonNullable<Failure | Success<U>>;

/**
 * Check if an Either is a Failure.
 */
const isFailure = <U>(e: Either<U>): e is Failure => {
  return e.error !== undefined;
};

/**
 * Check if an Either is a Success.
 */
const isSuccess = <U>(e: Either<U>): e is Success<U> => {
  return e.value !== undefined;
};

/**
 * Create a Failure
 */
const failure = (error: string): Failure => ({ error });

/**
 * Create a Success
 */
const success = <U>(value: U): Success<U> => ({ value });

const Result = {
  isFailure,
  isSuccess,
  failure,
  success,
};

export default Result;
