import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UserProfileId } from '../services/userProfileClient';

type SelectedList = {
  selectedListId: UserProfileId | null;
};

const initialState: SelectedList = {
  selectedListId: null,
};

export const personalizationSetting = createSlice({
  name: 'selectedList',
  initialState,
  reducers: {
    SelectedListValue: (state, action: PayloadAction<SelectedList['selectedListId']>) => {
      state.selectedListId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = personalizationSetting;

export const { SelectedListValue } = actions;

export default reducer;
