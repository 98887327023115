import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import prettyBytes from 'pretty-bytes';
import Result, { Either } from './Result';

export const jsonParse =
  <T>(guard: (o: any) => o is T) =>
  (text: string): Either<T> => {
    const parsed = JSON.parse(text);

    return guard(parsed) ? Result.success(parsed) : Result.failure('Error parsing JSON');
  };

export const objectKeys = <Obj extends {}>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[];
};

export const formatValue = (
  value: number | string | boolean | (string | number)[],
  formatType: 'bytes' | 'text' | 'date' | 'thousands' = 'thousands',
  options?: { decimalPlaces?: number }
): string => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (typeof value === 'number') {
    if (formatType === 'bytes') {
      return prettyBytes(value);
    }

    if (formatType === 'thousands') {
      return value.toLocaleString('en-GB', {
        maximumFractionDigits: (options && options.decimalPlaces) ?? 0,
        useGrouping: true,
      });
    }
  }

  if (typeof value === 'string') {
    if (formatType === 'date') {
      const date = parseISO(value.toString());
      return format(date, 'dd MMM yyyy HH:mm:ss');
    }
  }

  if (Array.isArray(value)) {
    const formatted = value.map((v) => {
      return formatValue(v, formatType);
    });

    return formatted.join(', ');
  }

  return value.toString();
};
