import { createStandaloneToast } from '@chakra-ui/react';
import axios from 'axios';
import { navigate } from 'raviger';
import { dwTheme } from '../components/App/App.theme';
import { LOGOUT_TOAST_ID, PATHS } from '../constants';
import Auth from '../helpers/Auth';
import { Logger } from '../helpers/Logger';
import Result from '../helpers/Result';
import { queryClient } from './queryClient';
const { toast } = createStandaloneToast({ theme: dwTheme });

const dwAxiosClient = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

dwAxiosClient.interceptors.request.use(
  (config) => {
    const tokenStatus = Auth.tokenStatus();

    if (tokenStatus === 'expired' || tokenStatus === 'no-token') {
      Auth.removeToken();
      queryClient.cancelQueries();
      navigate(PATHS.LOGIN_PATH);

      if (!toast.isActive(LOGOUT_TOAST_ID)) {
        toast({
          id: LOGOUT_TOAST_ID,
          title: 'Session expired',
          status: 'warning',
          duration: 7000,
          isClosable: true,
        });
      }

      const controller = new AbortController();
      const newConfig = { ...config, signal: controller.signal };
      controller.abort();
      return newConfig;
    }

    const token = Auth.getToken();

    if (Result.isSuccess(token)) {
      config.headers.set('Authorization', `Bearer ${token.value}`);
    }

    if (Result.isFailure(token)) {
      Logger.error('Error setting token on request');
    }

    return config;
  },
  (error) => {
    Logger.error('DW Axios Client error:', error);

    return Promise.reject(error);
  },
  { synchronous: true }
);

export default dwAxiosClient;
