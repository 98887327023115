import { Box, Link } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box
      background={'secondary'}
      p={'0px 20px'}
      display={'grid'}
      gridTemplateColumns={'1fr 1fr'}
      alignItems={'center'}
    >
      <Box display={'flex'}>
        {/* TODO: onClick missing */}
        <Link
          href={import.meta.env.VITE_APP_ABOUT_US}
          isExternal
          p={'10px'}
          aria-label="Visit About Us page"
        >
          <Box>About Us</Box>
        </Link>
      </Box>
      <Box id="footer-copy" display={'flex'} justifyContent={'end'} color={'gray'}>
        © 2023 Product of Dense Air {import.meta.env.VITE_APP_VERSION}
      </Box>
    </Box>
  );
};

export default Footer;
