import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  HStack,
  Input,
  useRadioGroup,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { usePlmnQueryClient } from '../../services/contextClient';
import { useNodesByTechnologyClient } from '../../services/nodesClient';
import { useCreateNodesList, useGetThresholdLists } from '../../services/userProfileClient';
import { NodesCollectionsForm } from '../../store/modalTabSlice';
import RadioCard from '../Radio/RadioCard';
import TagComponent from '../Select/TagComponent';

type TechnologyType = '4G' | '5G';

export type SelectedNodeType = {
  label: string;
  value: string;
  id: string;
};

export type CreateNodeValues = {
  listName: string;
  values: SelectedNodeType[];
};

export type CreateNode = {
  sectionName: string;
  listData: {
    listName: string;
    values: SelectedNodeType[];
  };
};

export type SelectedNodes = SelectedNodeType[];

const NodeCollectionForm = () => {
  const { register, handleSubmit } = useForm();
  const [listName, setListName] = useState<string>('');
  const [selectedNodes, setSelectedNodes] = useState<SelectedNodes>([]);
  const [isValidList, setIsValidList] = useState<boolean>(true);
  const existingUserProfileList = useGetThresholdLists();

  const toast = useToast();

  const dispatch = useDispatch();

  const existingNodeNameLists: string[] = [];

  existingUserProfileList.data?.map((item) => {
    const thresholdData = JSON.parse(item.payload);
    const name = thresholdData.listName;
    if (item.sectionName === 'NodeCollection') {
      existingNodeNameLists.push(name);
    }
    return null;
  });

  const [technologyType, setTechnologyType] = useState<TechnologyType>('4G');
  const options = ['4G', '5G'];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: '4G',
    onChange: (e: TechnologyType) => setTechnologyType(e),
  });

  const group = getRootProps();

  const plmnQuery = usePlmnQueryClient();

  const createNodeList = useCreateNodesList();

  useEffect(() => {
    if (listName !== '' && selectedNodes.length > 0 && listName.trim().length !== 0) {
      setIsValidList(false);
    } else {
      setIsValidList(true);
    }
  }, [listName, selectedNodes]);

  const { data: nodesData, isSuccess: nodesIsSuccess } = useNodesByTechnologyClient({
    plmns: plmnQuery.data?.allPlmns,
    technologyType: technologyType,
  });

  const onSubmit = () => {
    return;
  };

  const onNodeSelected = (data: SelectedNodeType) => {
    setSelectedNodes((prevSelectedNodes) => {
      const newNodeState = [...prevSelectedNodes];
      newNodeState.push(data);
      return newNodeState;
    });
  };

  const onDelete = (listName: string, itemId: string) => {
    setSelectedNodes((prevSelectedNodes) => {
      return prevSelectedNodes.filter((item) => {
        return item.id !== itemId;
      });
    });
  };

  const selectOptions = nodesIsSuccess
    ? Object.entries(nodesData).map(([nodeId, item]) => {
        const entry = {
          label: item.nodeName,
          value: nodeId,
          id: nodeId,
        };
        return entry;
      })
    : [];

  const selectedOptions = selectedNodes.map((item) => {
    return item.label;
  });

  const filteredOptions: { label: string; value: string; id: string }[] = selectOptions
    .filter((option) => {
      return !selectedOptions.includes(option.label);
    })
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

  const onNodeListSave = () => {
    if (existingNodeNameLists?.includes(listName)) {
      toast({
        title: 'List name is already in use.',
        description: 'Please change your list name',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top',
      });
      return null;
    }
    const nodeList = {
      sectionName: 'NodeCollection',
      listData: {
        listName: listName,
        values: selectedNodes,
      },
    };
    createNodeList.mutate(nodeList, {
      onSuccess: () => {
        toast({
          title: 'Your list is saved',
          description: '',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top',
        });
        dispatch(NodesCollectionsForm(false));
      },
    });
  };

  return (
    <>
      <Grid gridTemplateColumns={'1fr 1fr'} gridGap={10} minH={'450px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={'20px'}>
            <Input
              id={'node-collection-list-name-input-field'}
              placeholder="Node Collection Name"
              {...register('name')}
              onChange={(e) => {
                const inputValue = e.target.value;
                setListName(inputValue.trim());
              }}
            />
          </Box>
          <HStack {...group} mb={'10px'}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard key={value} {...radio}>
                  {value}
                </RadioCard>
              );
            })}
          </HStack>

          <Box mb={'20px'}>
            <Select
              id={'node-collection-select-dropdown'}
              options={filteredOptions}
              onChange={(selectedValue) => {
                if (selectedValue) {
                  onNodeSelected(selectedValue);
                }
              }}
              value={selectedNodes ? null : null}
            />
          </Box>
        </form>
        {listName !== '' || selectedNodes.length > 0 ? (
          <Box>
            <Card p={2} maxH={'250px'} overflow={'hidden'} overflowY={'scroll'}>
              <Heading size={'sm'} mb={'20px'} textTransform={'uppercase'}>
                {listName}
              </Heading>
              {[...selectedNodes]
                .sort((a, b) => {
                  return a.label.localeCompare(b.label);
                })
                .map((item) => {
                  return (
                    <Box key={`${item.id}`} mb={'5px'}>
                      <TagComponent
                        listName="Nodes"
                        label={`${item.label}`}
                        value={`${item.value}`}
                        delFunc={onDelete}
                      />
                    </Box>
                  );
                })}
            </Card>
          </Box>
        ) : null}
      </Grid>
      <Flex justifyContent={'end'} gap={'20px'}>
        <Button
          id={'cancel-button'}
          variant={'unstyled'}
          onClick={() => dispatch(NodesCollectionsForm(false))}
        >
          Cancel
        </Button>
        <Button
          id={'save-new-node-collection-button'}
          isDisabled={isValidList}
          onClick={onNodeListSave}
          background={'primary'}
        >
          Save
        </Button>
      </Flex>
    </>
  );
};

export default NodeCollectionForm;
