import { Button } from '@chakra-ui/button';
import { Card } from '@chakra-ui/card';
import { CardBody, Center, Flex, Image } from '@chakra-ui/react';
import { FC } from 'react';

type ProductCardProps = {
  id: string;
  image: string;
  title: string;
  description: string;
  detailedDescription: string;
  url: string;
};

const ProductCard: FC<ProductCardProps> = ({
  id,
  image,
  title,
  description,
  detailedDescription,
  url,
}) => {
  return (
    <Card align="center" _odd={{ background: '#efefef' }} borderRadius={'0px'}>
      <CardBody alignContent={'center'}>
        <Flex
          height="100%"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <Image src={image} alt={title} my="auto" maxHeight="150px" />
          <Center mt="4" mb="0">
            <Button
              as="a"
              minWidth={'150px'}
              href={url}
              id={id}
              background={'primary'}
              fontWeight={'semibold'}
              _hover={{ bg: 'primaryLightFifteenPercent' }}
            >
              {title}
            </Button>
          </Center>
        </Flex>
      </CardBody>
    </Card>
  );
};
export default ProductCard;
