import { Box, Center, Flex, Grid, Heading } from '@chakra-ui/react';
import { useQueryParams } from 'raviger';
import { FC, useEffect } from 'react';
import AdminImage from '../../assets/icons/admin.svg';
import AnalyticsImage from '../../assets/icons/analytics.svg';
import GeoImage from '../../assets/icons/geo.svg';
import LeadGen from '../../assets/icons/leadGen.svg';
import MonitorImage from '../../assets/icons/monitor.svg';
import ProductCard from '../../components/ProductCard/ProductCard';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Auth from '../../helpers/Auth';
import useGlobalAuth from '../../hooks/useGlobalAuth';

const features = [
  {
    buttonId: 'navigate-to-geo',
    image: GeoImage,
    title: 'Geo',
    description: 'Visualize Network',
    detailedDescription:
      'Explore the Geo app, a powerful tool that allows you to pinpoint network deadspots directly on an interactive map. Gain visibility into areas with weak or no network coverage, helping you identify opportunities for improvement and ensure reliable connectivity for your users.',
    url: import.meta.env.VITE_APP_GEO_URL,
    roleName: 'System-Geo',
    accessToInternal: true,
  },
  {
    buttonId: 'navigate-to-analytics',
    image: AnalyticsImage,
    title: 'Analytics',
    description: 'Uncover Trends and SLAs',
    detailedDescription:
      'Unleash the power of data with our Analytics app. Dive deep into comprehensive analytics and effortlessly analyze trends, patterns, and key performance indicators. Stay on top of your service level agreements and make data-driven decisions to drive success.',
    url: import.meta.env.VITE_APP_ANALYTICS_URL,
    roleName: 'System-Analytics',
    accessToInternal: true,
  },
  {
    buttonId: 'navigate-to-monitor',
    image: MonitorImage,
    title: 'Monitor',
    description: 'Stay on Top of Events',
    detailedDescription:
      'Experience real-time monitoring like never before. The Monitoring app keeps you informed about critical events triggered by APIs, applications, or the current status of Google Cloud Platform (GCP). Gain valuable insights and take proactive measures to maintain optimal performance and minimize downtime.',
    url: import.meta.env.VITE_APP_MONITOR_URL,
    roleName: 'System-Monitor',
    accessToInternal: true,
  },
  {
    buttonId: 'navigate-to-admin',
    image: AdminImage,
    title: 'Admin',
    description: 'Empowering User Administration',
    detailedDescription:
      'Introducing the new version of our Admin app. With an enhanced interface and advanced functionalities, the Admin app enables efficient user administration across all the applications within Portal. Accessible only to authorized personnel, it offers centralized control over user management, ensuring security and privacy.',
    url: import.meta.env.VITE_APP_ADMIN_URL,
    roleName: 'SystemAdmin',
  },
  {
    buttonId: 'navigate-to-lead-gen',
    // TODO: image to be updated
    image: LeadGen,
    title: 'Lead Generation',
    description: 'Search for an Oppertunity',
    detailedDescription: 'Find an oppertunity',
    url: import.meta.env.VITE_APP_LEAD_GEN_URL,
    roleName: 'System-Leadgen',
  },
];

const Homepage: FC = () => {
  useGlobalAuth();

  const [searchParams, setSearchParams] = useQueryParams();

  useEffect(() => {
    if (searchParams.token) {
      const newParams = { ...searchParams };
      delete newParams.token;
      setSearchParams(newParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  return (
    <Flex direction="column" minH="100vh">
      <Box>
        <Header />
      </Box>
      <Box p="8">
        <Heading color={'primary'}>Welcome to denseWare</Heading>
        <Center mt={'60px'}>
          <Grid
            gridTemplateColumns={'1fr 1fr 1fr'}
            gap={1}
            gridRowGap={5}
            height={'600px'}
            alignContent={'center'}
          >
            {features
              .filter((f) => {
                if (f.accessToInternal) {
                  if (Auth.isInternalUser()) {
                    return true;
                  }
                }

                return Auth.checkApplicationAccess(f.roleName);
              })
              .map((feature) => (
                <ProductCard
                  key={feature.buttonId}
                  id={feature.buttonId}
                  image={feature.image}
                  title={feature.title}
                  description={feature.description}
                  detailedDescription={feature.detailedDescription}
                  url={feature.url}
                />
              ))}
          </Grid>
        </Center>
      </Box>
      <Box mb="0" mt="auto">
        <Footer />
      </Box>
    </Flex>
  );
};

export default Homepage;
