import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type TabName = 'Threshold' | 'NodeCollection';
type ThresholdFormOpenState = boolean;
type NodeFormOpenState = boolean;
type PersonalizationModalOpenState = boolean;

type ThresholdEditForm = {
  isThresholdEditFormOpen: boolean;
  thresholdEditId: number | null;
};

type NodeCollectionEditForm = {
  isNodeCollectionEditFormOpen: boolean;
  nodeCollectionId: number | null;
};

type SetIsThresholdEditFormOpen = ThresholdEditForm['isThresholdEditFormOpen'];
type SetThresholdEditId = ThresholdEditForm['thresholdEditId'];

type SetIsNodeCollectionEditOpen = NodeCollectionEditForm['isNodeCollectionEditFormOpen'];
type SetNodeCollectionId = NodeCollectionEditForm['nodeCollectionId'];

type TabStates = {
  tabState: TabName;
  isThresholdFormOpen: ThresholdFormOpenState;
  isNodeCollectionFormOpen: NodeFormOpenState;
  isPersonalizationModalOpen: PersonalizationModalOpenState;
  thresholdsEditForm: ThresholdEditForm;
  nodeCollectionEditForm: NodeCollectionEditForm;
};

const initialState: TabStates = {
  tabState: 'Threshold',
  isThresholdFormOpen: false,
  isNodeCollectionFormOpen: false,
  isPersonalizationModalOpen: false,
  thresholdsEditForm: {
    isThresholdEditFormOpen: false,
    thresholdEditId: null,
  },
  nodeCollectionEditForm: {
    isNodeCollectionEditFormOpen: false,
    nodeCollectionId: null,
  },
};

export const modalTabSettings = createSlice({
  name: 'modalTabs',
  initialState,
  reducers: {
    ThresholdTab: (state, action: PayloadAction<TabStates['tabState']>) => {
      state.tabState = action.payload;
    },
    ThresholdFormState: (state, action: PayloadAction<TabStates['isThresholdFormOpen']>) => {
      state.isThresholdFormOpen = action.payload;
    },
    ThresholdEditFormState: (
      state,
      action: PayloadAction<{
        isThresholdEditFormOpen: SetIsThresholdEditFormOpen;
        thresholdEditId: SetThresholdEditId;
      }>
    ) => {
      state.thresholdsEditForm.isThresholdEditFormOpen = action.payload.isThresholdEditFormOpen;

      state.thresholdsEditForm.thresholdEditId = action.payload.thresholdEditId;
    },
    NodesCollectionsForm: (state, action: PayloadAction<TabStates['isNodeCollectionFormOpen']>) => {
      state.isNodeCollectionFormOpen = action.payload;
    },
    NodeCollectionEditFormFunc: (
      state,
      action: PayloadAction<{
        isNodeCollectionEditFormOpen: SetIsNodeCollectionEditOpen;
        nodeCollectionId: SetNodeCollectionId;
      }>
    ) => {
      state.nodeCollectionEditForm.isNodeCollectionEditFormOpen =
        action.payload.isNodeCollectionEditFormOpen;
      state.nodeCollectionEditForm.nodeCollectionId = action.payload.nodeCollectionId;
    },
    PersonalizationModalState: (
      state,
      action: PayloadAction<TabStates['isPersonalizationModalOpen']>
    ) => {
      state.isPersonalizationModalOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
const { actions, reducer } = modalTabSettings;

export const {
  ThresholdTab,
  ThresholdFormState,
  ThresholdEditFormState,
  NodesCollectionsForm,
  NodeCollectionEditFormFunc,
  PersonalizationModalState,
} = actions;

export default reducer;
