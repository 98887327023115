import { useNavigate } from 'raviger';
import { PATHS } from '../constants';
import Auth from '../helpers/Auth';
import Result from '../helpers/Result';
import { workoutredirect } from '../helpers/redirectUrl';

const useGlobalAuth = () => {
  const tokenResult = Auth.getToken();
  const tokenStatus = Auth.tokenStatus();
  const navigate = useNavigate();
  const url = window.location.search;
  const hashValue = window.location.hash;
  const urlParams = new URLSearchParams(url);

  if (Result.isSuccess(tokenResult) && tokenStatus === 'valid') {
    if (urlParams.has('redirectUrl')) {
      const redirectURL = workoutredirect(url);
      redirectURL.searchParams.append('token', tokenResult.value);
      const redirectURLString = redirectURL.toString() + decodeURI(hashValue);
      const currentURL = new URL(window.location.href);
      if (Auth.checkValidURL(currentURL)) {
        const adminLinks = JSON.parse(import.meta.env.VITE_APP_ADMIN_APP);
        // Checks for admin access
        const adminAccess = adminLinks.includes(redirectURL.host);
        const commercialLogin = JSON.parse(import.meta.env.VITE_APP_COMM_MOD_APP).includes(
          redirectURL.host
        );
        const leadGenLogin = JSON.parse(import.meta.env.VITE_APP_LEAD_GEN).includes(
          redirectURL.host
        );
        const monitorLogin = JSON.parse(import.meta.env.VITE_APP_MONITOR_APP).includes(
          redirectURL.host
        );
        const analyticsLogin = JSON.parse(import.meta.env.VITE_APP_ANALYTICS_APP).includes(
          redirectURL.host
        );

        if (adminAccess) {
          if (!Auth.checkApplicationAccess('SystemAdmin')) {
            navigate('/', { replace: true });
          }
        } else if (commercialLogin) {
          if (!Auth.checkApplicationAccess('CommercialModelUser')) {
            navigate('/', { replace: true });
          }
        } else if (leadGenLogin) {
          if (!Auth.checkApplicationAccess('System-Leadgen')) {
            navigate('/', { replace: true });
          }
        } else if (monitorLogin) {
          if (!Auth.isInternalUser()) {
            navigate('/', { replace: true });
          }
        } else if (analyticsLogin) {
          if (!Auth.isInternalUser()) {
            navigate('/', { replace: true });
          }
        } else {
          navigate(redirectURLString, { replace: true });
        }
      } else {
        navigate(`${PATHS.HOME_PATH}${window.location.hash}`, { replace: true });
      }
    }
  }
};

export default useGlobalAuth;
