/**
 * Consolidated Logger class. Keeps all the logging functions in one place for easy amendments (such as posting to a service).
 * Also allows to only disable eslint once here instead of in the places where needed.
 */

export class Logger {
  static log(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.log(message, ...args);
  }

  static error(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.error(message, ...args);
  }

  static warn(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.warn(message, ...args);
  }

  static info(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.info(message, ...args);
  }

  static debug(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.debug(message, ...args);
  }

  static trace(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.trace(message, ...args);
  }

  static group(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.group(message, ...args);
  }

  static groupCollapsed(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.groupCollapsed(message, ...args);
  }

  static groupEnd(): void {
    //eslint-disable-next-line no-console
    console.groupEnd();
  }

  static time(label: string): void {
    //eslint-disable-next-line no-console
    console.time(label);
  }

  static timeEnd(label: string): void {
    //eslint-disable-next-line no-console
    console.timeEnd(label);
  }

  static dir(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.dir(message, ...args);
  }

  static table(message: string, ...args: any[]): void {
    //eslint-disable-next-line no-console
    console.table(message, ...args);
  }
}
