/**
 * Set of functions for helping with Auth related tasks.
 *
 * @module
 */

import { isAfter } from 'date-fns';
import { decodeJwt } from 'jose';
import { Logger } from './Logger';
import Result, { Either } from './Result';

export type TokenPayload = {
  FirstName: string;
  LastName: string;
  Organization: string;
  PhoneNumber: string;
  ProviderId: string[];
  ViewDataValues: string;
  ViewRegionTag: string[];
  amr: string[];
  auth_time: number;
  client_id: string;
  email: string;
  exp: number;
  iat: number;
  idp: string;
  iss: string;
  jti: string;
  nbf: number;
  role: string[] | string;
  scope: string[];
  sub: string;
};

/**
 * @remarks the auth token is stored locally
 */

const AUTH_TOKEN_KEY = 'dw_authToken';

/**
 * Get the status of the token in storage.
 * @returns string
 */
const tokenStatus = (): 'no-token' | 'expired' | 'valid' => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const expiryResult = getAuthProperty('exp');

    if (Result.isSuccess(expiryResult)) {
      const expiryDate = new Date(expiryResult.value * 1000);
      const isValid = isAfter(expiryDate, new Date());

      if (isValid) {
        return 'valid';
      } else {
        return 'expired';
      }
    }
  }

  return 'no-token';
};

const checkApplicationAccess = (role: string): boolean => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const roles = getAuthProperty('role');
    const rolesArray = roles.value;
    if (rolesArray?.includes(role)) {
      return true;
    }
  }
  return false;
};

const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
};

/**
 * Save a token. Wrapped in Result.
 * @see {@link Result}
 */
const saveToken = (token: string): Either<string> => {
  try {
    localStorage.setItem(AUTH_TOKEN_KEY, token);

    return Result.success(token);
  } catch (e: any) {
    Logger.error(e);

    return Result.failure('Error saving token');
  }
};

/**
 * Get the token. Wrapped in Result.
 * @see {@link Result}
 */
const getToken = (): Either<string> => {
  try {
    const tokenString = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!tokenString) {
      return Result.failure('No token found');
    }

    return Result.success(tokenString);
  } catch (e: any) {
    Logger.error(e);
    return Result.failure('Error getting token');
  }
};

/**
 * Get a property from the auth token.
 * Generics should handle providing the type of the resulting value.
 * TS should warn if you pass a property name that doesn't exist on the {@link TokenPayload}
 *
 * @example
 * ```
 * type TokenPayload = {
 *  role: string[];
 * }
 * const authEmailResult = Auth.getAuthProperty('role');
 * // Type of authEmailResult should be string[]
 * ```
 *
 */
export const getAuthProperty = <K extends keyof TokenPayload>(
  propertyName: K
): Either<TokenPayload[K]> => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const token = tokenResult.value;
    const decodedToken = decodeJwt(token) as TokenPayload;
    const value = decodedToken[propertyName];

    if (value) {
      return Result.success(value);
    }

    Logger.error(`No value found in Auth Token for property ${propertyName}`);
    return Result.failure(`Property ${propertyName} not found`);
  }

  return tokenResult;
};

const checkValidURL = (url: URL) => {
  const token = getToken();
  if (token) {
    const redirectURLString = url.searchParams.get('redirectUrl') ?? '';
    const redirectURL = new URL(redirectURLString);

    if (JSON.parse(import.meta.env.VITE_APP_ALLOWED_REDIRECT).includes(redirectURL.host)) {
      return true;
    } else {
      return false;
    }
  }
};

const isAllowedRedirect = (urlString: string): boolean => {
  const allowedRedirects = JSON.parse(import.meta.env.VITE_APP_ALLOWED_REDIRECT);

  const isInAllowed = allowedRedirects.includes(urlString);

  return isInAllowed;
};

const isInternalUser = () => {
  const tokenResult = getToken();

  if (Result.isSuccess(tokenResult)) {
    const decodedToken = decodeJwt(tokenResult.value) as TokenPayload;

    const role = decodedToken.role;

    if (Array.isArray(role)) {
      return role.some((r) => r.includes('DenseAir'));
    } else {
      return role.includes('DenseAir');
    }
  } else {
    return false;
  }
};

type AppConfig = {
  role: string;
  urls: Array<string>;
  accessToInternal?: boolean;
};

const AppAccessConfig: Array<AppConfig> = [
  {
    role: 'System-Geo',
    urls: import.meta.env.VITE_APP_GEO_APP,
    accessToInternal: true,
  },
  {
    role: 'System-Analytics',
    urls: import.meta.env.VITE_APP_ANALYTICS_APP,
    accessToInternal: true,
  },
  {
    role: 'System-Monitor',
    urls: import.meta.env.VITE_APP_MONITOR_APP,
    accessToInternal: true,
  },
  { role: 'SystemAdmin', urls: import.meta.env.VITE_APP_ADMIN_APP },
  { role: 'CommercialModelUser', urls: import.meta.env.VITE_APP_COMM_MOD_APP },
  { role: 'System-Leadgen', urls: import.meta.env.VITE_APP_LEAD_GEN },
];

const getAppConfig = (appHost: string) => {
  const foundAppConfig = AppAccessConfig.find((c) => c.urls.includes(appHost));
  return foundAppConfig;
};

const isRestrictedApp = (appHost: string): boolean => {
  const foundAppConfig = getAppConfig(appHost);
  return foundAppConfig !== undefined;
};

const isAllowedSpecificApp = (appConfig: AppConfig): boolean => {
  const allowedRole = appConfig.role;

  return checkApplicationAccess(allowedRole);
};

const Auth = {
  tokenStatus,
  checkApplicationAccess,
  getToken,
  removeToken,
  saveToken,
  getAuthProperty,
  checkValidURL,
  isAllowedRedirect,
  isInternalUser,
  isRestrictedApp,
  getAppConfig,
  isAllowedSpecificApp,
  AppAccessConfig,
};

export default Auth;
