import { useQuery } from '@tanstack/react-query';
import { IntervalEnum } from '../services/da-sdk/types';
import denseairClient from './denseairClient';

type GetMeasureId = string[];

type GetMeasureOptions = {
  plmns: string[];
  nodeId: string[];
  cellNumber?: string[] | undefined;
  dateAndTimeStart: string;
  dateAndTimeEnd: string;
  interval: IntervalEnum;
  enabled: boolean;
};

const useGetMeasuresListClient = () => {
  return useQuery(['MeasuresList'], denseairClient.measures.getMeasuresList);
};

const useMultipleMeasuresClient = (measureIds: GetMeasureId, options: GetMeasureOptions) => {
  const { plmns, nodeId, cellNumber, dateAndTimeStart, dateAndTimeEnd, interval, enabled } =
    options;

  const nodeIdsToUse = nodeId.length === 0 ? undefined : nodeId;

  return useQuery({
    queryFn: async () => {
      const allMeasures = measureIds.map((measureId) => {
        return denseairClient.measures.getMeasuresForMeasureId({
          path: { measureId },
          params: {
            plmn: plmns,
            nodeId: nodeIdsToUse,
            cellNumber,
            dateAndTimeStart,
            dateAndTimeEnd,
            interval,
          },
        });
      });

      return Promise.all(allMeasures);
    },
    enabled,
    queryKey: [
      'measures',
      measureIds,
      plmns,
      nodeId,
      cellNumber,
      dateAndTimeStart,
      dateAndTimeEnd,
      interval,
    ],
    staleTime: 1000 * 60 * 3,
  });
};

export { useMultipleMeasuresClient, useGetMeasuresListClient };
