import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import modalTabSettings from './modalTabSlice';
import personalizationSetting from './personalizationSlice';

const rootReducer = combineReducers({
  modalTabSettings,
  personalizationSetting,
});

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: {},
});
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;
