import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { navigate } from 'raviger';
import { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaKey, FaUser } from 'react-icons/fa';
import { PATHS } from '../../constants';
import Auth from '../../helpers/Auth';
import Result from '../../helpers/Result';
import useGlobalAuth from '../../hooks/useGlobalAuth';
import { submitForm, validateNotEmpty } from './Login.helpers';

type FormValues = {
  email: string;
  password: string;
};

type LoginProps = {};

const Login: FC<LoginProps> = () => {
  useGlobalAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>();

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setFormError(null);

    const submitResult = await submitForm({
      username: data.email,
      password: data.password,
    });

    if (Result.isSuccess(submitResult)) {
      if (submitResult.value) {
        const saveResult = Auth.saveToken(submitResult.value);

        if (Result.isSuccess(saveResult)) {
          const currentUrl = new URL(window.location.href);

          const redirectUrlStringHasRedirect = currentUrl.searchParams.has('redirectUrl') ?? '';
          if (redirectUrlStringHasRedirect) {
            navigate(`${PATHS.AUTHORIZE_PATH}`, { replace: true, query: currentUrl.searchParams });
          } else {
            navigate(`${PATHS.HOME_PATH}${window.location.hash}`, { replace: true });
          }
        }
      }
    }

    if (Result.isFailure(submitResult)) {
      setFormError(submitResult.error);
    }
  };

  const initialValues = {
    email: '',
    password: '',
  };

  return (
    <Center height="100vh" width="100vw">
      <Container>
        <Center>
          <Image width="80%" mb="16" src="/denseware_wordmark.svg" />
        </Center>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing="5">
            <Box w="100%">
              <FormControl isInvalid={!!errors.email}>
                <FormLabel htmlFor="email" mb="0">
                  Email
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<Icon as={FaUser} />} />
                  <Input
                    defaultValue={initialValues.email}
                    {...register('email', { validate: validateNotEmpty })}
                    autoComplete="email"
                    id="email"
                    formNoValidate
                    type="email"
                  />
                </InputGroup>
                {errors.email ? <FormErrorMessage>{errors.email.message}</FormErrorMessage> : null}
              </FormControl>
            </Box>
            <Box w="100%">
              <FormControl isInvalid={!!errors.password}>
                <FormLabel htmlFor="password" mb="0">
                  Password
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents="none" children={<Icon as={FaKey} />} />
                  <Input
                    defaultValue={initialValues.password}
                    {...register('password', {
                      validate: validateNotEmpty,
                    })}
                    onKeyDown={(e) => {
                      const isCapsLockOn = e.getModifierState && e.getModifierState('CapsLock');
                      if (isCapsLockOn) {
                        setIsCapsLockOn(true);
                      } else {
                        setIsCapsLockOn(false);
                      }
                    }}
                    autoComplete="current-password"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                  />
                  <InputRightAddon
                    fontSize={'sm'}
                    cursor="pointer"
                    onClick={togglePassword}
                    id="show-password"
                  >
                    {showPassword ? 'HIDE' : 'SHOW'}
                  </InputRightAddon>
                </InputGroup>
                {errors.password ? (
                  <FormErrorMessage>{errors.password.message}</FormErrorMessage>
                ) : null}
                {isCapsLockOn ? (
                  <Text color="red.500" fontSize="sm">
                    Caps Lock is on
                  </Text>
                ) : null}
              </FormControl>
            </Box>
            <Button isLoading={isSubmitting} type="submit" id="login-submit">
              LOGIN
            </Button>
            {formError ? <Text color="red">{formError}</Text> : null}
          </VStack>
        </form>
      </Container>
    </Center>
  );
};

export default Login;
