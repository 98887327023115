import { Logger } from '../../helpers/Logger';
import Result, { Either } from '../../helpers/Result';
import { jsonParse } from '../../helpers/utils';

type LoginRequest = {
  username: string;
  password: string;
};

type LoginResponse = {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
};

export type LoginResult = Either<string>;

export const submitForm = async (options: LoginRequest): Promise<LoginResult> => {
  const { username, password } = options;

  try {
    if (!import.meta.env.VITE_APP_CLIENT_ID) {
      throw new Error('Missing Client ID env variable');
    }

    if (!import.meta.env.VITE_APP_CLIENT_SECRET) {
      throw new Error('Missing Client Secret env variable');
    }

    const body = new URLSearchParams();

    body.append('client_id', import.meta.env.VITE_APP_CLIENT_ID);
    body.append('client_secret', import.meta.env.VITE_APP_CLIENT_SECRET);
    body.append('grant_type', 'password');
    body.append('scope', 'denseware-api openid email profile');
    body.append('username', username);
    body.append('password', password);

    const response = await window.fetch(`${import.meta.env.VITE_APP_IDS_URL}/connect/token`, {
      body,
      method: 'POST',
    });

    if (response.status === 200) {
      const parseResult = jsonParse((o): o is LoginResponse => o.access_token)(
        await response.text()
      );
      if (Result.isSuccess(parseResult)) {
        return Result.success(parseResult.value.access_token);
      }
    }

    if (response.status >= 400 && response.status < 500) {
      return Result.failure('Error logging in. Please check your credentials.');
    }
    if (response.status >= 500) {
      return Result.failure('Server error. Please try again later.');
    }

    return Result.failure('Error logging in. Please try again later.');
  } catch (e: any) {
    Logger.error(`Error submitting login ${e}`);

    return Result.failure('Error logging in. Please try again later.');
  }
};

export const validateNotEmpty = (value: string | number | null | undefined): string | boolean => {
  if (value === '' || value === null || value === undefined) {
    return 'Required';
  }

  return true;
};
